import { default as bank110kfTto9oMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/bank.vue?macro=true";
import { default as bankR5H314HwhpMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/deposit/bank.vue?macro=true";
import { default as cardiUQr88oKDlMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/deposit/card.vue?macro=true";
import { default as codepayqSSZtSSbCQMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/deposit/codepay.vue?macro=true";
import { default as codepay2TmV8i5t8unMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/deposit/codepay2.vue?macro=true";
import { default as cryptoTvlSDU57nKMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/deposit/crypto.vue?macro=true";
import { default as momopCorwKdJ7EMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/deposit/momo.vue?macro=true";
import { default as betGXuIVmh7tRMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/history/bet.vue?macro=true";
import { default as transaction9D93y7BtecMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/history/transaction.vue?macro=true";
import { default as historyW6vBg1GvGPMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/history.vue?macro=true";
import { default as profilern3wGQRVrSMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/profile.vue?macro=true";
import { default as bankymxM647E7HMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/withdraw/bank.vue?macro=true";
import { default as carduY6vL6ZQfCMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/withdraw/card.vue?macro=true";
import { default as cryptoGy7UhPH0KfMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/withdraw/crypto.vue?macro=true";
import { default as accountW7bZqSu92AMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account.vue?macro=true";
import { default as _91alias_93fJOnaDjdW8Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/cau-hoi-thuong-gap/[alias].vue?macro=true";
import { default as cau_45hoi_45thuong_45gapScHAGb4hb0Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/cau-hoi-thuong-gap.vue?macro=true";
import { default as base_45buttonCTWlcm0choMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-button.vue?macro=true";
import { default as base_45count_45downNwsoae9Je9Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-count-down.vue?macro=true";
import { default as base_45dropdown_45radiocr006ykDXhMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-dropdown-radio.vue?macro=true";
import { default as base_45imgWRLUn7VdwoMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-img.vue?macro=true";
import { default as base_45inputOb3x8l7dL9Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-input.vue?macro=true";
import { default as base_45loadingUTctEkC04HMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-loading.vue?macro=true";
import { default as base_45modalfDqjYx3IIxMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-modal.vue?macro=true";
import { default as base_45money_45inputERJB5AVJ9hMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-money-input.vue?macro=true";
import { default as base_45pagination9SbKC1KrWDMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-pagination.vue?macro=true";
import { default as base_45select_45banke3YGzJRGuuMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-select-bank.vue?macro=true";
import { default as game_45itemyTTGgTBzJlMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/game-item.vue?macro=true";
import { default as index9fip2f6LuIMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/index.vue?macro=true";
import { default as _91type_93uKUyRyKNmUMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/game/[type].vue?macro=true";
import { default as game4TjSARaSd7Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/game.vue?macro=true";
import { default as _91alias_93Z89VqxWfavMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/gioi-thieu/[alias].vue?macro=true";
import { default as gioi_45thieuFIqMgg90xoMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/gioi-thieu.vue?macro=true";
import { default as _91alias_93rMzZ0Mb9Q2Meta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/huong-dan/[alias].vue?macro=true";
import { default as huong_45dano3VZsFdCeTMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/huong-dan.vue?macro=true";
import { default as indexxywDbGQRijMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/index.vue?macro=true";
import { default as _91id_93bcb81j28pmMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/khuyen-mai/[id].vue?macro=true";
import { default as indexCZnz9MT4VMMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/khuyen-mai/index.vue?macro=true";
import { default as _91id_93pa87Fw5eFWMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/khuyen-mai/the-loai/[id].vue?macro=true";
import { default as vipRaqS2UbHGfMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/khuyen-mai/vip.vue?macro=true";
import { default as khuyen_45mai56JgOH1yFdMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/khuyen-mai.vue?macro=true";
import { default as _91type_93hvSXB2lNMlMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/livecasino/[type].vue?macro=true";
import { default as livecasinoANfZsDaGhsMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/livecasino.vue?macro=true";
import { default as reset_45passwordNTk9FSbRKhMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/reset-password.vue?macro=true";
import { default as _91id_93rN5EDS9DnHMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/the-thao/[id].vue?macro=true";
import { default as indexavyaJRXjokMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/the-thao/index.vue?macro=true";
import { default as indexk1ovpue3hnMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/the-thao/lich-thi-dau/index.vue?macro=true";
import { default as _91alias_93JZkmRMDjloMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/tin-tuc/[alias].vue?macro=true";
import { default as indexeirWp8O38gMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/tin-tuc/index.vue?macro=true";
import { default as _91Slug_939g2csE2NJeMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/tin-tuc/the-loai/[Slug].vue?macro=true";
import { default as tin_45tucv5MuOp1fOKMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/tin-tuc.vue?macro=true";
import { default as verify_45mailFP6zm9HbBSMeta } from "/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/verify-mail.vue?macro=true";
export default [
  {
    name: accountW7bZqSu92AMeta?.name ?? "account",
    path: accountW7bZqSu92AMeta?.path ?? "/account",
    children: [
  {
    name: bank110kfTto9oMeta?.name ?? "account-bank",
    path: bank110kfTto9oMeta?.path ?? "bank",
    meta: bank110kfTto9oMeta || {},
    alias: bank110kfTto9oMeta?.alias || [],
    redirect: bank110kfTto9oMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/bank.vue").then(m => m.default || m)
  },
  {
    name: bankR5H314HwhpMeta?.name ?? "account-deposit-bank",
    path: bankR5H314HwhpMeta?.path ?? "deposit/bank",
    meta: bankR5H314HwhpMeta || {},
    alias: bankR5H314HwhpMeta?.alias || [],
    redirect: bankR5H314HwhpMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/deposit/bank.vue").then(m => m.default || m)
  },
  {
    name: cardiUQr88oKDlMeta?.name ?? "account-deposit-card",
    path: cardiUQr88oKDlMeta?.path ?? "deposit/card",
    meta: cardiUQr88oKDlMeta || {},
    alias: cardiUQr88oKDlMeta?.alias || [],
    redirect: cardiUQr88oKDlMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/deposit/card.vue").then(m => m.default || m)
  },
  {
    name: codepayqSSZtSSbCQMeta?.name ?? "account-deposit-codepay",
    path: codepayqSSZtSSbCQMeta?.path ?? "deposit/codepay",
    meta: codepayqSSZtSSbCQMeta || {},
    alias: codepayqSSZtSSbCQMeta?.alias || [],
    redirect: codepayqSSZtSSbCQMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/deposit/codepay.vue").then(m => m.default || m)
  },
  {
    name: codepay2TmV8i5t8unMeta?.name ?? "account-deposit-codepay2",
    path: codepay2TmV8i5t8unMeta?.path ?? "deposit/codepay2",
    meta: codepay2TmV8i5t8unMeta || {},
    alias: codepay2TmV8i5t8unMeta?.alias || [],
    redirect: codepay2TmV8i5t8unMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/deposit/codepay2.vue").then(m => m.default || m)
  },
  {
    name: cryptoTvlSDU57nKMeta?.name ?? "account-deposit-crypto",
    path: cryptoTvlSDU57nKMeta?.path ?? "deposit/crypto",
    meta: cryptoTvlSDU57nKMeta || {},
    alias: cryptoTvlSDU57nKMeta?.alias || [],
    redirect: cryptoTvlSDU57nKMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/deposit/crypto.vue").then(m => m.default || m)
  },
  {
    name: momopCorwKdJ7EMeta?.name ?? "account-deposit-momo",
    path: momopCorwKdJ7EMeta?.path ?? "deposit/momo",
    meta: momopCorwKdJ7EMeta || {},
    alias: momopCorwKdJ7EMeta?.alias || [],
    redirect: momopCorwKdJ7EMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/deposit/momo.vue").then(m => m.default || m)
  },
  {
    name: historyW6vBg1GvGPMeta?.name ?? "account-history",
    path: historyW6vBg1GvGPMeta?.path ?? "history",
    children: [
  {
    name: betGXuIVmh7tRMeta?.name ?? "account-history-bet",
    path: betGXuIVmh7tRMeta?.path ?? "bet",
    meta: betGXuIVmh7tRMeta || {},
    alias: betGXuIVmh7tRMeta?.alias || [],
    redirect: betGXuIVmh7tRMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/history/bet.vue").then(m => m.default || m)
  },
  {
    name: transaction9D93y7BtecMeta?.name ?? "account-history-transaction",
    path: transaction9D93y7BtecMeta?.path ?? "transaction",
    meta: transaction9D93y7BtecMeta || {},
    alias: transaction9D93y7BtecMeta?.alias || [],
    redirect: transaction9D93y7BtecMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/history/transaction.vue").then(m => m.default || m)
  }
],
    meta: historyW6vBg1GvGPMeta || {},
    alias: historyW6vBg1GvGPMeta?.alias || [],
    redirect: historyW6vBg1GvGPMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: profilern3wGQRVrSMeta?.name ?? "account-profile",
    path: profilern3wGQRVrSMeta?.path ?? "profile",
    meta: profilern3wGQRVrSMeta || {},
    alias: profilern3wGQRVrSMeta?.alias || [],
    redirect: profilern3wGQRVrSMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: bankymxM647E7HMeta?.name ?? "account-withdraw-bank",
    path: bankymxM647E7HMeta?.path ?? "withdraw/bank",
    meta: bankymxM647E7HMeta || {},
    alias: bankymxM647E7HMeta?.alias || [],
    redirect: bankymxM647E7HMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/withdraw/bank.vue").then(m => m.default || m)
  },
  {
    name: carduY6vL6ZQfCMeta?.name ?? "account-withdraw-card",
    path: carduY6vL6ZQfCMeta?.path ?? "withdraw/card",
    meta: carduY6vL6ZQfCMeta || {},
    alias: carduY6vL6ZQfCMeta?.alias || [],
    redirect: carduY6vL6ZQfCMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/withdraw/card.vue").then(m => m.default || m)
  },
  {
    name: cryptoGy7UhPH0KfMeta?.name ?? "account-withdraw-crypto",
    path: cryptoGy7UhPH0KfMeta?.path ?? "withdraw/crypto",
    meta: cryptoGy7UhPH0KfMeta || {},
    alias: cryptoGy7UhPH0KfMeta?.alias || [],
    redirect: cryptoGy7UhPH0KfMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account/withdraw/crypto.vue").then(m => m.default || m)
  }
],
    meta: accountW7bZqSu92AMeta || {},
    alias: accountW7bZqSu92AMeta?.alias || [],
    redirect: accountW7bZqSu92AMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: cau_45hoi_45thuong_45gapScHAGb4hb0Meta?.name ?? "cau-hoi-thuong-gap",
    path: cau_45hoi_45thuong_45gapScHAGb4hb0Meta?.path ?? "/cau-hoi-thuong-gap",
    children: [
  {
    name: _91alias_93fJOnaDjdW8Meta?.name ?? "cau-hoi-thuong-gap-alias",
    path: _91alias_93fJOnaDjdW8Meta?.path ?? ":alias",
    meta: _91alias_93fJOnaDjdW8Meta || {},
    alias: _91alias_93fJOnaDjdW8Meta?.alias || [],
    redirect: _91alias_93fJOnaDjdW8Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/cau-hoi-thuong-gap/[alias].vue").then(m => m.default || m)
  }
],
    meta: cau_45hoi_45thuong_45gapScHAGb4hb0Meta || {},
    alias: cau_45hoi_45thuong_45gapScHAGb4hb0Meta?.alias || [],
    redirect: cau_45hoi_45thuong_45gapScHAGb4hb0Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/cau-hoi-thuong-gap.vue").then(m => m.default || m)
  },
  {
    name: base_45buttonCTWlcm0choMeta?.name ?? "dev-base-button",
    path: base_45buttonCTWlcm0choMeta?.path ?? "/dev/base-button",
    meta: base_45buttonCTWlcm0choMeta || {},
    alias: base_45buttonCTWlcm0choMeta?.alias || [],
    redirect: base_45buttonCTWlcm0choMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-button.vue").then(m => m.default || m)
  },
  {
    name: base_45count_45downNwsoae9Je9Meta?.name ?? "dev-base-count-down",
    path: base_45count_45downNwsoae9Je9Meta?.path ?? "/dev/base-count-down",
    meta: base_45count_45downNwsoae9Je9Meta || {},
    alias: base_45count_45downNwsoae9Je9Meta?.alias || [],
    redirect: base_45count_45downNwsoae9Je9Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-count-down.vue").then(m => m.default || m)
  },
  {
    name: base_45dropdown_45radiocr006ykDXhMeta?.name ?? "dev-base-dropdown-radio",
    path: base_45dropdown_45radiocr006ykDXhMeta?.path ?? "/dev/base-dropdown-radio",
    meta: base_45dropdown_45radiocr006ykDXhMeta || {},
    alias: base_45dropdown_45radiocr006ykDXhMeta?.alias || [],
    redirect: base_45dropdown_45radiocr006ykDXhMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-dropdown-radio.vue").then(m => m.default || m)
  },
  {
    name: base_45imgWRLUn7VdwoMeta?.name ?? "dev-base-img",
    path: base_45imgWRLUn7VdwoMeta?.path ?? "/dev/base-img",
    meta: base_45imgWRLUn7VdwoMeta || {},
    alias: base_45imgWRLUn7VdwoMeta?.alias || [],
    redirect: base_45imgWRLUn7VdwoMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-img.vue").then(m => m.default || m)
  },
  {
    name: base_45inputOb3x8l7dL9Meta?.name ?? "dev-base-input",
    path: base_45inputOb3x8l7dL9Meta?.path ?? "/dev/base-input",
    meta: base_45inputOb3x8l7dL9Meta || {},
    alias: base_45inputOb3x8l7dL9Meta?.alias || [],
    redirect: base_45inputOb3x8l7dL9Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-input.vue").then(m => m.default || m)
  },
  {
    name: base_45loadingUTctEkC04HMeta?.name ?? "dev-base-loading",
    path: base_45loadingUTctEkC04HMeta?.path ?? "/dev/base-loading",
    meta: base_45loadingUTctEkC04HMeta || {},
    alias: base_45loadingUTctEkC04HMeta?.alias || [],
    redirect: base_45loadingUTctEkC04HMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-loading.vue").then(m => m.default || m)
  },
  {
    name: base_45modalfDqjYx3IIxMeta?.name ?? "dev-base-modal",
    path: base_45modalfDqjYx3IIxMeta?.path ?? "/dev/base-modal",
    meta: base_45modalfDqjYx3IIxMeta || {},
    alias: base_45modalfDqjYx3IIxMeta?.alias || [],
    redirect: base_45modalfDqjYx3IIxMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45money_45inputERJB5AVJ9hMeta?.name ?? "dev-base-money-input",
    path: base_45money_45inputERJB5AVJ9hMeta?.path ?? "/dev/base-money-input",
    meta: base_45money_45inputERJB5AVJ9hMeta || {},
    alias: base_45money_45inputERJB5AVJ9hMeta?.alias || [],
    redirect: base_45money_45inputERJB5AVJ9hMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-money-input.vue").then(m => m.default || m)
  },
  {
    name: base_45pagination9SbKC1KrWDMeta?.name ?? "dev-base-pagination",
    path: base_45pagination9SbKC1KrWDMeta?.path ?? "/dev/base-pagination",
    meta: base_45pagination9SbKC1KrWDMeta || {},
    alias: base_45pagination9SbKC1KrWDMeta?.alias || [],
    redirect: base_45pagination9SbKC1KrWDMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-pagination.vue").then(m => m.default || m)
  },
  {
    name: base_45select_45banke3YGzJRGuuMeta?.name ?? "dev-base-select-bank",
    path: base_45select_45banke3YGzJRGuuMeta?.path ?? "/dev/base-select-bank",
    meta: base_45select_45banke3YGzJRGuuMeta || {},
    alias: base_45select_45banke3YGzJRGuuMeta?.alias || [],
    redirect: base_45select_45banke3YGzJRGuuMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/base-select-bank.vue").then(m => m.default || m)
  },
  {
    name: game_45itemyTTGgTBzJlMeta?.name ?? "dev-game-item",
    path: game_45itemyTTGgTBzJlMeta?.path ?? "/dev/game-item",
    meta: game_45itemyTTGgTBzJlMeta || {},
    alias: game_45itemyTTGgTBzJlMeta?.alias || [],
    redirect: game_45itemyTTGgTBzJlMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/game-item.vue").then(m => m.default || m)
  },
  {
    name: index9fip2f6LuIMeta?.name ?? "dev",
    path: index9fip2f6LuIMeta?.path ?? "/dev",
    meta: index9fip2f6LuIMeta || {},
    alias: index9fip2f6LuIMeta?.alias || [],
    redirect: index9fip2f6LuIMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: game4TjSARaSd7Meta?.name ?? "game",
    path: game4TjSARaSd7Meta?.path ?? "/game",
    children: [
  {
    name: _91type_93uKUyRyKNmUMeta?.name ?? "game-type",
    path: _91type_93uKUyRyKNmUMeta?.path ?? ":type",
    meta: _91type_93uKUyRyKNmUMeta || {},
    alias: _91type_93uKUyRyKNmUMeta?.alias || [],
    redirect: _91type_93uKUyRyKNmUMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/game/[type].vue").then(m => m.default || m)
  }
],
    meta: game4TjSARaSd7Meta || {},
    alias: game4TjSARaSd7Meta?.alias || [],
    redirect: game4TjSARaSd7Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/game.vue").then(m => m.default || m)
  },
  {
    name: gioi_45thieuFIqMgg90xoMeta?.name ?? "gioi-thieu",
    path: gioi_45thieuFIqMgg90xoMeta?.path ?? "/gioi-thieu",
    children: [
  {
    name: _91alias_93Z89VqxWfavMeta?.name ?? "gioi-thieu-alias",
    path: _91alias_93Z89VqxWfavMeta?.path ?? ":alias",
    meta: _91alias_93Z89VqxWfavMeta || {},
    alias: _91alias_93Z89VqxWfavMeta?.alias || [],
    redirect: _91alias_93Z89VqxWfavMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/gioi-thieu/[alias].vue").then(m => m.default || m)
  }
],
    meta: gioi_45thieuFIqMgg90xoMeta || {},
    alias: gioi_45thieuFIqMgg90xoMeta?.alias || [],
    redirect: gioi_45thieuFIqMgg90xoMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: huong_45dano3VZsFdCeTMeta?.name ?? "huong-dan",
    path: huong_45dano3VZsFdCeTMeta?.path ?? "/huong-dan",
    children: [
  {
    name: _91alias_93rMzZ0Mb9Q2Meta?.name ?? "huong-dan-alias",
    path: _91alias_93rMzZ0Mb9Q2Meta?.path ?? ":alias",
    meta: _91alias_93rMzZ0Mb9Q2Meta || {},
    alias: _91alias_93rMzZ0Mb9Q2Meta?.alias || [],
    redirect: _91alias_93rMzZ0Mb9Q2Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/huong-dan/[alias].vue").then(m => m.default || m)
  }
],
    meta: huong_45dano3VZsFdCeTMeta || {},
    alias: huong_45dano3VZsFdCeTMeta?.alias || [],
    redirect: huong_45dano3VZsFdCeTMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/huong-dan.vue").then(m => m.default || m)
  },
  {
    name: indexxywDbGQRijMeta?.name ?? "index",
    path: indexxywDbGQRijMeta?.path ?? "/",
    meta: indexxywDbGQRijMeta || {},
    alias: indexxywDbGQRijMeta?.alias || [],
    redirect: indexxywDbGQRijMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/index.vue").then(m => m.default || m)
  },
  {
    path: khuyen_45mai56JgOH1yFdMeta?.path ?? "/khuyen-mai",
    children: [
  {
    name: _91id_93bcb81j28pmMeta?.name ?? "khuyen-mai-id",
    path: _91id_93bcb81j28pmMeta?.path ?? ":id",
    meta: _91id_93bcb81j28pmMeta || {},
    alias: _91id_93bcb81j28pmMeta?.alias || [],
    redirect: _91id_93bcb81j28pmMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/khuyen-mai/[id].vue").then(m => m.default || m)
  },
  {
    name: indexCZnz9MT4VMMeta?.name ?? "khuyen-mai",
    path: indexCZnz9MT4VMMeta?.path ?? "",
    meta: indexCZnz9MT4VMMeta || {},
    alias: indexCZnz9MT4VMMeta?.alias || [],
    redirect: indexCZnz9MT4VMMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/khuyen-mai/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pa87Fw5eFWMeta?.name ?? "khuyen-mai-the-loai-id",
    path: _91id_93pa87Fw5eFWMeta?.path ?? "the-loai/:id",
    meta: _91id_93pa87Fw5eFWMeta || {},
    alias: _91id_93pa87Fw5eFWMeta?.alias || [],
    redirect: _91id_93pa87Fw5eFWMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/khuyen-mai/the-loai/[id].vue").then(m => m.default || m)
  },
  {
    name: vipRaqS2UbHGfMeta?.name ?? "khuyen-mai-vip",
    path: vipRaqS2UbHGfMeta?.path ?? "vip",
    meta: vipRaqS2UbHGfMeta || {},
    alias: vipRaqS2UbHGfMeta?.alias || [],
    redirect: vipRaqS2UbHGfMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/khuyen-mai/vip.vue").then(m => m.default || m)
  }
],
    name: khuyen_45mai56JgOH1yFdMeta?.name ?? undefined,
    meta: khuyen_45mai56JgOH1yFdMeta || {},
    alias: khuyen_45mai56JgOH1yFdMeta?.alias || [],
    redirect: khuyen_45mai56JgOH1yFdMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/khuyen-mai.vue").then(m => m.default || m)
  },
  {
    name: livecasinoANfZsDaGhsMeta?.name ?? "livecasino",
    path: livecasinoANfZsDaGhsMeta?.path ?? "/livecasino",
    children: [
  {
    name: _91type_93hvSXB2lNMlMeta?.name ?? "livecasino-type",
    path: _91type_93hvSXB2lNMlMeta?.path ?? ":type",
    meta: _91type_93hvSXB2lNMlMeta || {},
    alias: _91type_93hvSXB2lNMlMeta?.alias || [],
    redirect: _91type_93hvSXB2lNMlMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/livecasino/[type].vue").then(m => m.default || m)
  }
],
    meta: livecasinoANfZsDaGhsMeta || {},
    alias: livecasinoANfZsDaGhsMeta?.alias || [],
    redirect: livecasinoANfZsDaGhsMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/livecasino.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordNTk9FSbRKhMeta?.name ?? "reset-password",
    path: reset_45passwordNTk9FSbRKhMeta?.path ?? "/reset-password",
    meta: reset_45passwordNTk9FSbRKhMeta || {},
    alias: reset_45passwordNTk9FSbRKhMeta?.alias || [],
    redirect: reset_45passwordNTk9FSbRKhMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_93rN5EDS9DnHMeta?.name ?? "the-thao-id",
    path: _91id_93rN5EDS9DnHMeta?.path ?? "/the-thao/:id",
    meta: _91id_93rN5EDS9DnHMeta || {},
    alias: _91id_93rN5EDS9DnHMeta?.alias || [],
    redirect: _91id_93rN5EDS9DnHMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/the-thao/[id].vue").then(m => m.default || m)
  },
  {
    name: indexavyaJRXjokMeta?.name ?? "the-thao",
    path: indexavyaJRXjokMeta?.path ?? "/the-thao",
    meta: indexavyaJRXjokMeta || {},
    alias: indexavyaJRXjokMeta?.alias || [],
    redirect: indexavyaJRXjokMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/the-thao/index.vue").then(m => m.default || m)
  },
  {
    name: indexk1ovpue3hnMeta?.name ?? "the-thao-lich-thi-dau",
    path: indexk1ovpue3hnMeta?.path ?? "/the-thao/lich-thi-dau",
    meta: indexk1ovpue3hnMeta || {},
    alias: indexk1ovpue3hnMeta?.alias || [],
    redirect: indexk1ovpue3hnMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/the-thao/lich-thi-dau/index.vue").then(m => m.default || m)
  },
  {
    path: tin_45tucv5MuOp1fOKMeta?.path ?? "/tin-tuc",
    children: [
  {
    name: _91alias_93JZkmRMDjloMeta?.name ?? "tin-tuc-alias",
    path: _91alias_93JZkmRMDjloMeta?.path ?? ":alias",
    meta: _91alias_93JZkmRMDjloMeta || {},
    alias: _91alias_93JZkmRMDjloMeta?.alias || [],
    redirect: _91alias_93JZkmRMDjloMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/tin-tuc/[alias].vue").then(m => m.default || m)
  },
  {
    name: indexeirWp8O38gMeta?.name ?? "tin-tuc",
    path: indexeirWp8O38gMeta?.path ?? "",
    meta: indexeirWp8O38gMeta || {},
    alias: indexeirWp8O38gMeta?.alias || [],
    redirect: indexeirWp8O38gMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91Slug_939g2csE2NJeMeta?.name ?? "tin-tuc-the-loai-Slug",
    path: _91Slug_939g2csE2NJeMeta?.path ?? "the-loai/:Slug",
    meta: _91Slug_939g2csE2NJeMeta || {},
    alias: _91Slug_939g2csE2NJeMeta?.alias || [],
    redirect: _91Slug_939g2csE2NJeMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/tin-tuc/the-loai/[Slug].vue").then(m => m.default || m)
  }
],
    name: tin_45tucv5MuOp1fOKMeta?.name ?? undefined,
    meta: tin_45tucv5MuOp1fOKMeta || {},
    alias: tin_45tucv5MuOp1fOKMeta?.alias || [],
    redirect: tin_45tucv5MuOp1fOKMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/tin-tuc.vue").then(m => m.default || m)
  },
  {
    name: verify_45mailFP6zm9HbBSMeta?.name ?? "verify-mail",
    path: verify_45mailFP6zm9HbBSMeta?.path ?? "/verify-mail",
    meta: verify_45mailFP6zm9HbBSMeta || {},
    alias: verify_45mailFP6zm9HbBSMeta?.alias || [],
    redirect: verify_45mailFP6zm9HbBSMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/BUILD/ps23-web/pages/verify-mail.vue").then(m => m.default || m)
  }
]